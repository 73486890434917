<template>
	<div class="cbox">
		<div class="headbar">

			<div style="padding-top: 14px;">
				<el-breadcrumb>
					<el-breadcrumb-item>开票结算</el-breadcrumb-item>
					<el-breadcrumb-item>开票结算审批流程配置</el-breadcrumb-item>

				</el-breadcrumb>
			</div>

		</div>
		<div class="bcontent">
			<el-tabs v-model="activeName" @tab-click="changeTab">
				<el-tab-pane v-for="(item, idx) in tabList" :key="idx" :label="item.name" :name="item.name">
				</el-tab-pane>

			</el-tabs>

			<div style="height:80vh;overflow: auto;">
				<processmaker3 code="dianzi1" title="收款主体：代理人" v-if="activeName == '收款主体：代理人'"></processmaker3>
				<processmaker4 code="dianzi2" title="收款主体：个人非代理人" v-if="activeName == '收款主体：个人非代理人'"></processmaker4>
				
				<div style="height: 50px;">

				</div>
			</div>




		</div>


	</div>
</template>

<script>

import processmaker3 from '../com/processmaker'
import processmaker4 from '../com/processmaker'
export default {
	components: {
		processmaker3,processmaker4
	},
	data() {
		return {
			activeName: "收款主体：代理人",
			tabList: [ {
				name: "收款主体：代理人"
			}, {
				name: "收款主体：个人非代理人"
			}],


		}
	},

	mounted() {





	},
	methods: {

		changeTab() {
			console.log(this.activeName)
		}




	}
}
</script>
